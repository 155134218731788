import React from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const CAMERAS_CATEGORIES = [
    {
        id: 'camaras',
        label: 'Cámaras',
        subcategories: []
    },
    {
        id: 'cam-action',
        label: 'Acción',
        subcategories: []
    },
    {
        id: 'cam-vlog',
        label: 'Vlog',
        subcategories: []
    },
    {
        id: 'cam-lenses',
        label: 'Lentes',
        subcategories: []
    },
    {
        id: 'cam-accesories',
        label: 'Accesorios',
        subcategories: []
    },
    {
        id: 'cam-drones',
        label: 'Drones',
        subcategories: []
    }
];


export const CameraCategoriesButtons = ({
    offer_code,
    token,
    handleSearch,
    disabled
}) => {
    const { t } = useTranslation();
    const handleClick = (path, searchParams) => {
        window.history.pushState({}, '', `/${token ? token + "/" : ""}ofertas/${path}`);
        handleSearch({
            change_page: true,
            loading_searching: searchParams.loading_searching,
            p_offer_code: searchParams.p_offer_code
        });
    };

    const isActiveCategory = (categoryId) => {
        const category = CAMERAS_CATEGORIES.find(cat => cat.id === categoryId);
        return category && (
            offer_code === category.id ||
            category.subcategories.some(sub => sub.id === offer_code)
        );
    };

    return <div className='d-flex flex-wrap justify-content-center align-items-center mb-2'>
        {CAMERAS_CATEGORIES.map(category => (
            <Button
                key={category.id}
                className='me-2 mb-2'
                variant={isActiveCategory(category.id) ? "success" : "outline-success"}
                size='sm'
                onClick={() => handleClick(category.id, {
                    loading_searching: "searching",
                    p_offer_code: category.id
                })}
                disabled={disabled}
            >
                {t(category.label)}
            </Button>
        ))}
    </div>
}
