const utils = {
    ProperCase: (str) => {
        return str.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase());
    },

    urlencode: (str) => {
        return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
            return '%' + c.charCodeAt(0).toString(16);
        });
    },
    formatCurrency: (amount, currency) => {
        //currency viene del backend arn:aws:lambda:us-east-1:645160913840:function:BBY_product_fetch_by_sku
        if (!amount) return "";

        let val = new Intl.NumberFormat('es-MX', {
            style: 'currency',
            currency: currency || 'MXN',
            minimumFractionDigits: 2
        }).format(amount);
        //if (currency === "MXN") return val.replace("$", "Pesos ")
        if (currency === "DOP") return val.replace("DOP", "RD$")
        if (currency === "PEN") return val.replace("PEN", "S/")
        if (currency === "BRL") return val.replace("BRL", "R$")


        return val;
    },

    getCountryDialingCode: (countryCode) => {
        switch (countryCode) {
            case 'CR':
                return '+506';
            case 'MX':
                return '+52';
            case 'CO':
                return '+57';
            case "CL":
                return "+56";
            case "PE":
                return "+51";
            case "US":
                return "+1";
            case "DO":
                return "+1";
            case "BR":
                return "+55";
            case "AE":
                return "+971";
            default:
                return '+1';

        }
    },

    getWhatsappNumber: (countryCode) => {
        switch (countryCode) {
            case 'CR':
                return '+13259999181';
            case 'MX':
                return '+19546562300';
            case 'CO':
                return '+13054499666';
            case "CL":
                return "+56352490360";
            case "PE":
                return "+19548666222";
            case "DO":
                return "+13056151234";
            case "BR":
                return "+19252484710";
            case "AE":
                return "+13055703666";
            default:
                return '+19546562300';
        }
    },
    countries: ["MX", "CR", "PE", "CL", "CO", "DO", "BR", "AE"],
    countryName: (countryCode) => {
        switch (countryCode) {
            case "DO":
                return 'República Dominicana';
            case 'MX':
                return 'México';
            case 'CR':
                return 'Costa Rica';
            case 'PE':
                return 'Perú';
            case 'CL':
                return 'Chile';
            case 'CO':
                return 'Colombia';
            case 'BR':
                return 'Brasil';
            case 'AE':
                return 'United Arab Emirates';
            default:

                return countryCode;
        }
    },
    countryCurrency: (countryCode) => {
        switch (countryCode) {
            case "DO":
                return 'RD$';
            case 'MX':
                return 'Pesos';
            case 'CR':
                return 'Colones';
            case 'PE':
                return 'Soles';
            case 'CL':
                return 'Pesos';
            case 'CO':
                return 'Pesos';
            case 'BR':
                return 'Reais';
            case 'AE':
                return 'Dirhams';
            default:

                return countryCode;
        }

    },
    countryFlag: (countryCode) => {
        switch (countryCode) {
            case "DO":
                return '🇩🇴';
            case 'MX':
                return '🇲🇽';
            case 'CR':
                return '🇨🇷';
            case 'PE':
                return '🇵🇪';
            case 'CL':
                return '🇨🇱';
            case 'CO':
                return '🇨🇴';
            case 'BR':
                return '🇧🇷';
            case 'AE':
                return '🇦🇪';
            default:

                return countryCode;
        }
    },

    metodosDePago: (countryCode) => {
        switch (countryCode) {
            case 'CR':
                return ['tarjetas'];
            case 'MX':
                return ['tarjetas', 'mercadopago', 'oxxo_spei'];
            case 'CO':
                return ['tarjetas', 'nequi'];
            case 'CL':
                return ['tarjetas', 'mercadopago', 'webpay'];
            case 'PE':
                return ['tarjetas', 'pagoefectivo'];
            case 'DO':
                return ['tarjetas'];
            case 'BR':
                return ['tarjetas', 'boleto', 'pix'];
            case 'AE':
                return ['tarjetas'];
            default: return ['tarjetas']

        }
    }
}
export default utils;